import { useNuxtApp } from '#imports';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useAuthStore } from '~/store/auth';
import { useErrorHandling } from '~/shared/composable/useErrorHandling';
import { AuthService } from '~/data/services/auth.service';
import type {
  AuthBasicResponse,
  AuthChangePasswordPayload,
  AuthLoginPayload,
  AuthResetPasswordPayload,
} from '~/data/services/types/auth.type';
import { useUserStore } from '~/store/user';
import { createUserModelBase } from '~/data/model/user.model';
import { errorCodes } from '~/data/domain/response/errors/error-codes';
import { toastErrorNotification } from '~/utils/toast-notification-helper';

export const useLogin = () => {
  const { $customFetch } = useNuxtApp();
  const authRepo = AuthService($customFetch);
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const router = useRouter();
  const toast = useToast();

  async function getUser(): Promise<void> {
    const userData = await authRepo.getMe();

    userStore.setUser(createUserModelBase(userData));
  }

  async function makeUserLoggedIn(apiToken: string): Promise<void> {
    authStore.setToken(apiToken);
    await getUser();
  }

  async function makeUserLoggedOut(): Promise<void> {
    authStore.setToken(null);
    userStore.clearUser();

    // todo: improve to redirect only when page is protected;
    await router.replace('/');
  }

  const { getErrorMessage } = useErrorHandling();

  async function login(payload: AuthLoginPayload): Promise<AuthBasicResponse> {
    return await authRepo.login(payload);
  }

  async function recoveryPassword(payload: AuthResetPasswordPayload): Promise<void> {
    await authRepo.resetPassword(payload);
  }

  async function changePassword(payload: AuthChangePasswordPayload): Promise<void> {
    await authRepo.changePassword(payload);
  }

  async function logout(): Promise<void> {
    await authRepo.logout();

    await makeUserLoggedOut();
  }

  async function googleLogin() {
    try {
      const url = await authRepo.google();
      if (url) {
        window.location.href = url;
      }
    } catch (e) {
      console.error(e);
      toastErrorNotification(toast, {
        body: getErrorMessage(errorCodes.E_000),
      });
    }
  }

  return {
    googleLogin,
    login,
    recoveryPassword,
    changePassword,
    logout,
    makeUserLoggedIn,
    makeUserLoggedOut,
    getUser,
  };
};
